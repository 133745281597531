#inputNumber {
    width: 3em;
    color: #151141;
    border-radius: 25px;
    margin: 20px 0 0 20px;
    padding: 10px;
    border: #fff 1px solid; 
}
#inputNumber:focus {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    border: #fff 1px solid;
}
.inputnumber {
    font-size: 1.5em;
}
.date {
    font-size: 1.8em;
    margin-top: 20px;
}
#dt-container {
    color: #fff;
    margin: 10px 0;
    padding: 30px;
    border: none; 
    text-align: center;
}

@media (max-width: 768px) {
    #inputNumber{
        width: 2em;
    }
    #date-container {
        max-width: 500px;
        margin: auto;
    }
}
@media (max-width: 500px) {
    #inputNumber{
        width: 3em;
    }
}