@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

@media (min-width: 1400px) {
    #residence-button {
        font-size: 1.5rem;
        padding: 20px;
        border: none;
        border-radius: 50px;
        line-height: 0.8;
        background: #0e6ba8;
        transition: all 300ms;
    }
    #begin-button {
        font-size: 1.5rem;
        padding: 20px;
        border: none;
        border-radius: 50px;
        line-height: 0.8;
        background: #fff;
        color: #151141;
        border: 1px solid #151141;
        transition: all 300ms;
    }
    .wizzard-residencia {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        margin-bottom: 80px;
    }
    .si-no-container {
        display: flex;
        justify-content: center;
    }
}

#f-container {
    display: grid;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
}
#c-form {
    display: grid;
    justify-content: center;
    background: #151141;
    padding: 80px;
    border-radius: 25px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.check-logo {
    width: 30px;
}
.check-logo-white {
    width: 1.4em;
}
#c-form label {
    color: #fff;
}
#form-title {
    margin-top: 20px;
    font-size: 24px;
    color: #fff;
    margin-bottom: 12px;
    font-weight: bold;
    display: block;
    text-align: center;
}
#c-form form {
    display: flex;
    flex-direction: column;
}
#c-form label {
    margin-bottom: 10px;
}
#c-form input {
    padding: 10px;
    border: none;
    border-radius: 25px;
    margin-bottom: 20px;
}
#c-form input:focus {
    outline: none;
    box-shadow: 0px 4px 8px #3eb2ff;
    transform: scale(1.02);
    transition: transform 0.3s ease-in-out;
}
#c-form button[type="submit"] {
    background-color: #fff;
    color: #0e6ba8;
    font-size: 2em;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 50%;
    align-self: center;
}
#c-form button[type="submit"]:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px 4px 8px #3eb2ff;
}
#list-container{
    display: grid;
    justify-content: center;
    margin-top: 70px;
    padding: 30px;
}
.start {
    font-size: 2em;
    font-weight: 600;
    line-height: 1;
    margin: 30px auto;
    text-align: center;
}
.list-item {
    list-style: none;
    margin: 10px 50px ;
}
.residencia-text {
    font-size: 2em;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    align-self: center;
    height: fit-content;
    padding-bottom: 1em;
}
.wizzard-residencia {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    margin: 80px 0 80px 0;
}
.si-no-container {
    display: flex;
    justify-content: center;
}
#date-container {
    display: grid;
    justify-content: center;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    height: 20em;
}
.dec-date {
    color: #fff;
    font-size: 2em;
    font-weight: 600;
    margin: 20px 0 0 0;
    text-align: center;
    padding-top: 30px;
}
#residence-button {
    font-size: 2em;
    padding: 30px 30px;
    border: none;
    border-radius: 50px;
    width: 30em;
    line-height: 0.8;
    background: #0e6ba8;
    transition: all 300ms;
}
#residence-button:hover {
    transform: scale(1.05);
    background: #151141;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
#begin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    color: #fff;
    margin-top: 50px;
    padding: 5em;
}
.texto-content {
    font-size: 2em;
    margin: 20px auto;
    color: #fff;
}
.p-content {
    font-size: 1.5em;
    padding: 10px 50px;
    margin-bottom: 20px;
}
#begin-button {
    font-size: 2em;
    padding: 30px 30px;
    border: none;
    border-radius: 50px;
    width: 30em;
    line-height: 0.8;
    background: #fff;
    color: #151141;
    border: 1px solid #151141;
    transition: all 300ms;
}
#begin-button:hover {
    transform: scale(1.05);
    background: #151141;
    color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}


@media (max-width:768px) {
    #f-container {
        margin-top: 90px;
    }
    #c-form {
        max-width: 350px;
        padding: 0 20px;
        box-sizing: border-box;
        height: 22em;
    }
    #form-title {
        font-size: 1.2em;
    }
    .check-logo {
        width: 1.2em;
    }
    #list-container{
        margin-top: 90px;
        padding: 20px;
    }
    .check-logo-white {
        width: 1.2em;
    }
    .residencia-text {
        font-size: 1.5em;
        width: 15em;
    }
    .wizzard-residencia {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        margin: 40px 0 40px 0;
    }
    #btn-residencia:hover {
        background-color: #151141;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);
    }
    .si-no-container {
        display: flex;
        justify-content: center;
    }
    #date-container {
        height: fit-content;
    }
    .start, 
    .dec-date {
        font-size: 1.5em;
        width: fit-content;
    }
    .list {
        padding: 10px;
    }
    .list-item {
        font-size: 1em;
        margin: 10px 5px;
    }
    #residence-button {
        font-size: 1.2rem;
        width: 18em;
        border: none;
        border-radius: 50px;
        line-height: 0.8;
        background: #0e6ba8;
        transition: all 300ms;
    }
    #residence-button:hover {
        transform: scale(1.05);
        background: #151141;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #begin-container {
        margin-top: 0;
        padding: 1em;
    }
    .texto-content {
        font-size: 1.5em;
        margin: 20px auto;
    }
    .p-content {
        font-size: 1.2em;
        line-height: 1em;
        padding: 10px 20px;
        margin-top: 20px;
        order: 2;
    }
    #begin-button {
        font-size: 1.2rem;
        width: 18em;
        border: none;
        border-radius: 50px;
        line-height: 0.8;
        background: #fff;
        color: #151141;
        border: 1px solid #151141;
        transition: all 300ms;
    }
    #begin-button:hover {
        transform: scale(1.05);
        background: #151141;
        color: #fff;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
}
