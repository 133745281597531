#declaracion-container {
    margin-top: 90px;
    display: grid;
}
#welcome-container {
    width: 100%;
    background: #fff url(../assets/list-man.png) right center/contain no-repeat;
    margin-bottom: 20px;
}
#welcome-title {
    font-size: 2em;
    color: #151141;
    font-weight: 600;
}
#title-logo {
    width: 1em;
    margin-bottom: 10px;
}

#welcome-message {
    color: #151141;
    font-size: 1.2em;
    font-weight: 600;
    width: 60%;
    text-align: justify;
}
#welcome-message2 {
    color: #151141;
    font-size: 1em;
    font-weight: 600;
    width: 60%;
    text-align: justify;
}
#welcome-message3,
#welcome-message4 {
    color: #151141;
    font-size: 1em;
    font-weight: 600;
    text-align: justify;
}
.check-logo-white {
    width: 1.2em;
    margin-bottom: .5em;
}
.list-item-welcome {
    list-style: none;
    font-weight: 600;
}
#new-declaracion {
    width: 20%;
    font-size: 1.2em;
    font-weight: 600;
}

.ayuda-año-gravable {
    height: 17px !important;
}

@media (max-width: 768px) {
    #welcome-container {
        background: #fff;
        margin-bottom: 20px;
    }
    #welcome-title {
        font-size: 1.6em;
        color: #151141;
        font-weight: 600;
    }
    #title-logo {
        width: 1em;
        margin-bottom: 10px;
    }
    
    #welcome-message {
        color: #151141;
        font-size: 1.2em;
        font-weight: 600;
        width: 100%;
        text-align: justify;
    }
    #welcome-message2 {
        color: #151141;
        font-size: 1em;
        font-weight: 600;
        width: 100%;
        text-align: justify;
    }
    #welcome-message3,
    #welcome-message4 {
        color: #151141;
        font-size: 1em;
        font-weight: 600;
        text-align: justify;
    }
    .check-logo-white {
        width: 1.2em;
        margin-bottom: .5em;
    }
    .list-welcome {
        background: #fff url(../assets/list-man.png) right center/contain no-repeat;
        padding-left: 0;
    }
    .list-item-welcome {
        list-style: none;
        font-weight: 600;
    }
    #new-declaracion {
        width: 100%;
        font-size: 1.2em;
        font-weight: 600;
    }
}

@media (max-width: 680px) {
    #welcome-container {
        background: #fff;
        margin-bottom: 20px;
    }
    #welcome-title {
        font-size: 1.4em;
        color: #151141;
        font-weight: 600;
    }
    #title-logo {
        width: .8em;
        margin-bottom: 10px;
    }
    
    #welcome-message {
        color: #151141;
        font-size: 1.2em;
        font-weight: 600;
        width: 100%;
        text-align: justify;
    }
    #welcome-message2 {
        color: #151141;
        font-size: 1em;
        font-weight: 600;
        width: 100%;
        text-align: justify;
    }
    #welcome-message3,
    #welcome-message4 {
        color: #151141;
        font-size: 1em;
        font-weight: 600;
        text-align: justify;
    }
    .check-logo-white {
        width: 1.2em;
        margin-bottom: .5em;
    }
    .list-welcome {
        background: #fff url(../assets/list-man.png) right center/contain no-repeat;
        padding-left: 0;
    }
    .list-item-welcome {
        list-style: none;
        font-weight: 600;
        font-size: .9em;
    }
    #new-declaracion {
        width: 100%;
        font-size: 1.2em;
        font-weight: 600;
    }
}