@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap);
* {
    font-family: 'Montserrat', sans-serif;
}

@media (min-width: 1400px) {
    #contenedor {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 55em;
        margin-top: 80px;
        padding: 0 80px;
        background: radial-gradient(#0e6aa8b0, #0e6ba8);
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #c-div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    #c-h {
        color: #fff;
        font-size: 3rem;
        font-weight: 800;
        width: 600px;
        margin: 0 0 30px 0;
        line-height: 1;
    }
    #c-p {
        font-size: 2rem;
        width: 600px;
        padding: 0 20px 30px;
        color: #fff;
        line-height: 1;
    }
    #pc {
        width: 700px;
        padding: 20px;
    }
    #loginBtn {
        font-size: 2rem;
        font-weight: 600;
        padding: 20px;
        border: none;
        border-radius: 50px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        line-height: 0.8;
        background: #fff;
        color: #0e6ba8;
        border: 1px solid #0e6ba8;
        transition: all 300ms;
    }
    #loginBtn:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        background: #fff;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        color: #151141;
        border: 2px solid #151141;
    }
    #section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40em;
        margin: 0;
        color: #151141;
        line-height: 1;
    }
    #section:hover {
        cursor: pointer;
    }
    #sino {
        width: 30em;
    }
    #p-sec,
    #p-sec2 {
        font-size: 2rem;
        font-weight: 600;
        margin: 0;
    }
    #section-3 {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        background: radial-gradient(#0e6aa8b0, #0e6ba8);
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #ventajas {
        padding: 0 50px;
        font-size: 2.5rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 0.5;
    }
    .logo-text {
        width: 250px;
    }
    .logo-text {
        cursor: pointer;
    }
    #boxes {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 80px;
        grid-gap: 80px;
        gap: 80px;
        padding: 1em;
    }
    #box {
        width: 300px;
        font-size: 1.5rem;
        border-radius: 25px;
        text-align: center;
        /* background: radial-gradient(#0e6aa8b0, #0e6ba8); */
        color: white;
        transition: all 300ms;
    }
    #box:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        cursor: pointer;
        /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25); */
    }
    #cerdito,
    #reloj,
    #candado {
        width: 200px;
    }
    .box-text {
        color: #fff;
        border-radius: 25px;
        font-weight: 800;
    }
    .p-box {
        color: #fff;
    
    }
    
    #section-5 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
    }
    #texto-content {
        width: 20em;
        font-size: 3rem;
        font-weight: 600;
        color: #151141;
        line-height: 1;
    }
    #begin-btn {
        font-size: 2rem;
        padding: 30px;
        border: none;
        border-radius: 50px;
        width: 100%;
        line-height: 0.8;
        background: #0e6ba8;
        transition: all 300ms;
        margin-bottom: 30px;
    }
    #begin-btn:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        background: #151141;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #parr-content {
        font-size: 1.5rem;
        line-height: 1;
        width: 40em;
        margin: 0 0 80px;
    }
}
#contenedor {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
    padding: 0 80px;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    grid-gap: 5em;
    gap: 5em;
}
#c-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
#c-h {
    color: #fff;
    font-size: 3rem;
    font-weight: 800;
    width: 600px;
    margin: 0 0 30px 0;
    line-height: 1;
}
#c-p {
    font-size: 2rem;
    width: 600px;
    padding: 0 20px 30px;
    color: #fff;
    line-height: 1;
}
#pc {
    width: 700px;
    padding: 20px;
}
#loginBtn {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 20px;
    border: none;
    border-radius: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 0.8;
    background: #fff;
    color: #0e6ba8;
    border: 1px solid #0e6ba8;
    transition: all 300ms;
}
#loginBtn:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    color: #151141;
    border: 2px solid #151141;
}
#section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    grid-gap: 0;
    gap: 0;
    width: 100%;
    margin: 0;
    padding: 5em;
    text-align: center;
    color: #151141;
    line-height: 1;
}
#sino {
    width: 20em;
}
#section:hover {
    cursor: pointer;
}
#p-sec2 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    width: 50%;
}
#section-3 {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
#ventajas {
    padding: 0 50px;
    font-size: 2.5rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 0.5;
}
.logo-text {
    width: 250px;
}
.logo-text {
    cursor: pointer;
}
#boxes {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 80px;
    grid-gap: 80px;
    gap: 80px;
    padding: 1em;
}
#box {
    width: 300px;
    font-size: 1.5rem;
    border-radius: 25px;
    text-align: center;
    /* background: radial-gradient(#0e6aa8b0, #0e6ba8); */
    color: white;
    transition: all 300ms;
}
#box:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    cursor: pointer;
    /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25); */
}
#cerdito,
#reloj,
#candado {
    width: 200px;
}
.box-text {
    color: #fff;
    border-radius: 25px;
    font-weight: 800;
}
.p-box {
    color: #fff;

}
#section-5 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
}
#texto-content {
    width: 20em;
    font-size: 3rem;
    font-weight: 600;
    color: #151141;
    line-height: 1;
}
#begin-btn {
    font-size: 2rem;
    padding: 30px;
    border: none;
    border-radius: 50px;
    width: 100%;
    line-height: 0.8;
    background: #0e6ba8;
    transition: all 300ms;
    margin-bottom: 30px;
}
#begin-btn:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background: #151141;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
#parr-content {
    font-size: 1.5rem;
    line-height: 1;
    width: 40em;
    margin: 0 0 80px;
}


@media (max-width: 768px) {
    #contenedor {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        grid-gap: 0;
        gap: 0;
        height: auto;
        /* background: linear-gradient(-160deg, #0e6ba8 55%, #151141 40%); */
        background: radial-gradient(#0e6aa8b0, #0e6ba8);
    }
    #c-h {
        font-size: 2rem;
        font-weight: 800;
        width: 100%;
        margin: 100px 0 10px 0;
        text-align: center;
        color: #fff;
    }
    #c-p {
        font-size: 1.5rem;
        width: 100%;
        padding: 0 10px;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
    }
    #loginBtn,
    #begin-btn {
        font-size: 1.5rem;
        padding: 15px;
        border: 1px solid #0e6ba8;
        border-radius: 50px;
        width: 100%;
        line-height: 0.8;
        background: #fff;
        transition: all 300ms;
    }
    #loginBtn:hover,
    #begin-btn:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        background: #fff;
        color: #151141;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        border: 2px solid #151141;
    }
    #pc {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
    #section {
        display: flex;
        flex-direction: column;
        grid-gap: 1em;
        gap: 1em;
        padding: 10px;
        margin: 20px 0 20px 0;
        color: #151141;
        line-height: 1.2;
        transition: all 300ms;
    }
    #section:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
    #sino {
        width: 15em;
        order: 1;
    }
    #p-sec2 {
        font-size: 1.5rem;
        width: 100%;
        order: 0;
    }
    #section-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #ventajas {
        margin: 30px 0 0 0;
        padding: 10px;
        font-size: 2rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 1;
    }
    .logo-text {
        width: 200px;
    }
    #boxes {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }
    #box {
        width: 100%;
        font-size: 1.5rem;
        border-radius: 25px;
        text-align: center;
        color: white;
        transition: all 300ms;
    }
    #cerdito,
    #reloj,
    #candado {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }

    .box-text {
        color: #fff;
        border-radius: 20px;
        font-size: 2rem;
    }
    .p-box {
        font-size: 1.5rem;
        line-height: 1em;
    }

    #section-5 {
        height: auto;
        text-align: center;
        display: block;
    }

    #texto-content {
        width: 15.5em;
        padding: 1em;
        margin: 19.2px 0 0 0;
        font-size: 1.5rem;
        font-weight: 700;
        color: #151141;
        line-height: 1;
    }

    #parr-content {
        font-size: 1.2rem;
        line-height: 1.2;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 1em;
        max-width: 600px;
        margin-bottom: 1em;
    }

    #begin-btn {
        font-size: 1.2rem;
        margin: 0;
        padding: 15px;
        border: none;
        border-radius: 50px;
        width: 18em;
        max-width: 400px;
        line-height: 1.2;
        background: #0e6ba8;
        transition: all 300ms;
    }
    #begin-btn:hover {
        background-color: #151141;
        color: #fff;
    }
}

.justify-content-end {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    transition: all 300ms;
}
#navbar {
    font-family: 'Montserrat', sans-serif;
    margin-left: auto;
    grid-gap: 10px;
    gap: 10px;
}
.logo {
    width: 180px;
    margin-left: 50px;
}
.navbar-toggler.collapsed,
.navbar-toggler {
    border-radius: 3em;
    border: none;
}
#menu.nav-link {
    font-size: 1em;
    color: #151141;
    border-radius: 25px;
    transition: all 300ms;
    padding: 10px 20px;
}
#menu.nav-link:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0px 4px 8px rgb(190, 190, 190);
    color: #fff;
    background-color: #0e6ba8;
}
#qsLoginBtn {
    font-size: 1em;
    margin-right: 100px;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background: #0e6ba8;
    transition: all 300ms;
}
#qsLoginBtn.btn-primary:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background: #151141;
    border: none;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.dropdown-toggle.btn.btn-secondary {
    margin-right: 150px;
    padding: 10px 20px;
    font-size: 1rem;
    background: #0e6ba8;
    border: none;
    border-radius: 25px;
}


@media (max-width: 768px) {
    .logo {
        margin: 0;
        width: 150px;
    }
    #qsLoginBtn {
        margin-right: 0px;
    }
    #menu.nav-link {
        width: 320px;
    }
}

.cal-btn {
    border-radius: 2em;
    width: 75%;
    padding: 30px;
    font-size: 2em;
    line-height: 0.8;
    background: white;
    color: #0e6ba8;
    border: 1px solid #0e6ba8;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 300ms;
}
.cal-btn:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background-color: #151141;
    color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    border: none;
}

@media (max-width: 768px) {
    .cal-btn {
        width: 18em;
    }
}

@media (min-width: 1400px) {
    .cal-btn {
        width: 30em;
        font-size: 2rem;
        padding: 30px;
    }
}

.section-6 {
    height: 25em;
    display: grid;
    align-items: center;
    justify-content: center;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    padding: 1em;
    text-align: center;
    padding: 50px;
}
.h-section {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #fff;
}
.p-section {
    font-size: 1.8em;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .section-6 {
        height: 25em;
        display: grid;
        align-items: center;
        justify-content: center;
    }
    .h-section {
        font-size: 1.5em;
        margin-top: 0;
        margin-bottom: 0.5em;
        color: #fff;
    }
    .p-section {
        font-size: 1.2em;
        line-height: 1.5;
    }
}
.jumbo-container {
    display: grid;
    justify-content: center;
    height: 1vh;
}
.jumbotron {
    margin: 100px 300px;
    color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.text-center {
    box-shadow: none;
}
.firebaseui-idp-button.mdl-button.mdl-js-button {
    border-radius: 25px;
    padding: 0 20px;
    font-size: 3rem;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in, 
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up {
    border-radius: 25px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.firebaseui-id-submit.mdl-button--colored {
    border-radius: 25px;
    background: #151141;
}
.firebaseui-id-secondary-link {
    color: #151141;
    border-radius: 25px;
}

@media (max-width: 768px) {
    .jumbotron {
        margin: 100px 0;
    }
}
.hamster-container {
    display: grid;
    justify-content: center;
    height: 15em;
    margin-top: 8em;
}

.wheel-and-hamster {
    --dur: 1s;
    position: relative;
    width: 12em;
    height: 12em;
    font-size: 14px;
}

.wheel,
.hamster,
.hamster div,
.spoke {
    position: absolute;
}

.wheel,
.spoke {
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.wheel {
    background: radial-gradient(100% 100% at center,hsla(0,0%,60%,0) 47.8%,hsl(0,0%,60%) 48%);
    z-index: 2;
}

.hamster {
    -webkit-animation: hamster var(--dur) ease-in-out infinite;
            animation: hamster var(--dur) ease-in-out infinite;
    top: 50%;
    left: calc(50% - 3.5em);
    width: 7em;
    height: 3.75em;
    -webkit-transform: rotate(4deg) translate(-0.8em,1.85em);
            transform: rotate(4deg) translate(-0.8em,1.85em);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    z-index: 1;
}

.hamster__head {
    -webkit-animation: hamsterHead var(--dur) ease-in-out infinite;
            animation: hamsterHead var(--dur) ease-in-out infinite;
    background: #0e6ba8;
    border-radius: 70% 30% 0 100% / 40% 25% 25% 60%;
    box-shadow: 0 -0.25em 0 #caeaffb0 inset,
            0.75em -1.55em 0 #caeaffb0 inset;
    top: 0;
    left: -2em;
    width: 2.75em;
    height: 2.5em;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
}

.hamster__ear {
    -webkit-animation: hamsterEar var(--dur) ease-in-out infinite;
            animation: hamsterEar var(--dur) ease-in-out infinite;
    background: hsl(0,90%,85%);
    border-radius: 50%;
    box-shadow: -0.25em 0 #0e6ba8 inset;
    top: -0.25em;
    right: -0.25em;
    width: 0.75em;
    height: 0.75em;
    -webkit-transform-origin: 50% 75%;
            transform-origin: 50% 75%;
}

.hamster__eye {
    -webkit-animation: hamsterEye var(--dur) linear infinite;
            animation: hamsterEye var(--dur) linear infinite;
    background-color: hsl(0,0%,0%);
    border-radius: 50%;
    top: 0.375em;
    left: 1.25em;
    width: 0.5em;
    height: 0.5em;
}

.hamster__nose {
    background: hsl(0,90%,75%);
    border-radius: 35% 65% 85% 15% / 70% 50% 50% 30%;
    top: 0.75em;
    left: 0;
    width: 0.2em;
    height: 0.25em;
}

.hamster__body {
    -webkit-animation: hamsterBody var(--dur) ease-in-out infinite;
            animation: hamsterBody var(--dur) ease-in-out infinite;
    background: #0e6ba8;
    border-radius: 50% 30% 50% 30% / 15% 60% 40% 40%;
    box-shadow: 0 -0.25em 0 #caeaffb0 inset,
            0.75em -1.55em 0 #caeaffb0 inset;
    top: 0.25em;
    left: 2em;
    width: 4.5em;
    height: 3em;
    -webkit-transform-origin: 17% 50%;
            transform-origin: 17% 50%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.hamster__limb--fr,
.hamster__limb--fl {
    -webkit-clip-path: polygon(0 0,100% 0,70% 80%,60% 100%,0% 100%,40% 80%);
            clip-path: polygon(0 0,100% 0,70% 80%,60% 100%,0% 100%,40% 80%);
    top: 2em;
    left: 0.5em;
    width: 1em;
    height: 1.5em;
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
}

.hamster__limb--fr {
    -webkit-animation: hamsterFRLimb var(--dur) linear infinite;
            animation: hamsterFRLimb var(--dur) linear infinite;
    background: linear-gradient(#caeaffb0 80%,hsl(0,90%,75%) 80%);
    -webkit-transform: rotate(15deg) translateZ(-1px);
            transform: rotate(15deg) translateZ(-1px);
}

.hamster__limb--fl {
    -webkit-animation: hamsterFLLimb var(--dur) linear infinite;
            animation: hamsterFLLimb var(--dur) linear infinite;
    background: linear-gradient(#caeaffb0 80%,hsl(0,90%,85%) 80%);
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
}

.hamster__limb--br,
.hamster__limb--bl {
    border-radius: 0.75em 0.75em 0 0;
    -webkit-clip-path: polygon(0 0,100% 0,100% 30%,70% 90%,70% 100%,30% 100%,40% 90%,0% 30%);
            clip-path: polygon(0 0,100% 0,100% 30%,70% 90%,70% 100%,30% 100%,40% 90%,0% 30%);
    top: 1em;
    left: 2.8em;
    width: 1.5em;
    height: 2.5em;
    -webkit-transform-origin: 50% 30%;
            transform-origin: 50% 30%;
}

.hamster__limb--br {
    -webkit-animation: hamsterBRLimb var(--dur) linear infinite;
            animation: hamsterBRLimb var(--dur) linear infinite;
    background: linear-gradient(#caeaffb0 90%,hsl(0,90%,75%) 90%);
    -webkit-transform: rotate(-25deg) translateZ(-1px);
            transform: rotate(-25deg) translateZ(-1px);
}

.hamster__limb--bl {
    -webkit-animation: hamsterBLLimb var(--dur) linear infinite;
            animation: hamsterBLLimb var(--dur) linear infinite;
    background: linear-gradient(#caeaffb0 90%,hsl(0,90%,85%) 90%);
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
}

.hamster__tail {
    -webkit-animation: hamsterTail var(--dur) linear infinite;
            animation: hamsterTail var(--dur) linear infinite;
    background: #0e6ba8;
    border-radius: 0.25em 50% 50% 0.25em;
    box-shadow: 0 -0.2em 0 #0e6ba8 inset;
    top: 1.5em;
    right: -0.5em;
    width: 1em;
    height: 0.5em;
    -webkit-transform: rotate(30deg) translateZ(-1px);
            transform: rotate(30deg) translateZ(-1px);
    -webkit-transform-origin: 0.25em 0.25em;
            transform-origin: 0.25em 0.25em;
}

.spoke {
    -webkit-animation: spoke var(--dur) linear infinite;
            animation: spoke var(--dur) linear infinite;
    background: radial-gradient(100% 100% at center,hsl(0,0%,60%) 4.8%,hsla(0,0%,60%,0) 5%),
            linear-gradient(hsla(0,0%,55%,0) 46.9%,hsl(0,0%,65%) 47% 52.9%,hsla(0,0%,65%,0) 53%) 50% 50% / 99% 99% no-repeat;
}

/* Animations */
@-webkit-keyframes hamster {
from, to {
    -webkit-transform: rotate(4deg) translate(-0.8em,1.85em);
            transform: rotate(4deg) translate(-0.8em,1.85em);
}

50% {
    -webkit-transform: rotate(0) translate(-0.8em,1.85em);
            transform: rotate(0) translate(-0.8em,1.85em);
}
}
@keyframes hamster {
from, to {
    -webkit-transform: rotate(4deg) translate(-0.8em,1.85em);
            transform: rotate(4deg) translate(-0.8em,1.85em);
}

50% {
    -webkit-transform: rotate(0) translate(-0.8em,1.85em);
            transform: rotate(0) translate(-0.8em,1.85em);
}
}

@-webkit-keyframes hamsterHead {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
}
}

@keyframes hamsterHead {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
}
}

@-webkit-keyframes hamsterEye {
from, 90%, to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
}

95% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
}
}

@keyframes hamsterEye {
from, 90%, to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
}

95% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
}
}

@-webkit-keyframes hamsterEar {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
}
}

@keyframes hamsterEar {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
}
}

@-webkit-keyframes hamsterBody {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
}
}

@keyframes hamsterBody {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
}
}

@-webkit-keyframes hamsterFRLimb {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(50deg) translateZ(-1px);
            transform: rotate(50deg) translateZ(-1px);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-30deg) translateZ(-1px);
            transform: rotate(-30deg) translateZ(-1px);
}
}

@keyframes hamsterFRLimb {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(50deg) translateZ(-1px);
            transform: rotate(50deg) translateZ(-1px);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-30deg) translateZ(-1px);
            transform: rotate(-30deg) translateZ(-1px);
}
}

@-webkit-keyframes hamsterFLLimb {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
}
}

@keyframes hamsterFLLimb {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
}
}

@-webkit-keyframes hamsterBRLimb {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(-60deg) translateZ(-1px);
            transform: rotate(-60deg) translateZ(-1px);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(20deg) translateZ(-1px);
            transform: rotate(20deg) translateZ(-1px);
}
}

@keyframes hamsterBRLimb {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(-60deg) translateZ(-1px);
            transform: rotate(-60deg) translateZ(-1px);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(20deg) translateZ(-1px);
            transform: rotate(20deg) translateZ(-1px);
}
}

@-webkit-keyframes hamsterBLLimb {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg);
}
}

@keyframes hamsterBLLimb {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg);
}
}

@-webkit-keyframes hamsterTail {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(30deg) translateZ(-1px);
            transform: rotate(30deg) translateZ(-1px);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(10deg) translateZ(-1px);
            transform: rotate(10deg) translateZ(-1px);
}
}

@keyframes hamsterTail {
from, 25%, 50%, 75%, to {
    -webkit-transform: rotate(30deg) translateZ(-1px);
            transform: rotate(30deg) translateZ(-1px);
}

12.5%, 37.5%, 62.5%, 87.5% {
    -webkit-transform: rotate(10deg) translateZ(-1px);
            transform: rotate(10deg) translateZ(-1px);
}
}

@-webkit-keyframes spoke {
from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

to {
    -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn);
}
}

@keyframes spoke {
from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

to {
    -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn);
}
}

@media (max-width: 768px) {
    .hamster-container {
        height: 12em;
        margin-top: 5em;
    }
}
#inputNumber {
    width: 3em;
    color: #151141;
    border-radius: 25px;
    margin: 20px 0 0 20px;
    padding: 10px;
    border: #fff 1px solid; 
}
#inputNumber:focus {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    border: #fff 1px solid;
}
.inputnumber {
    font-size: 1.5em;
}
.date {
    font-size: 1.8em;
    margin-top: 20px;
}
#dt-container {
    color: #fff;
    margin: 10px 0;
    padding: 30px;
    border: none; 
    text-align: center;
}

@media (max-width: 768px) {
    #inputNumber{
        width: 2em;
    }
    #date-container {
        max-width: 500px;
        margin: auto;
    }
}
@media (max-width: 500px) {
    #inputNumber{
        width: 3em;
    }
}
* {
    font-family: 'Montserrat', sans-serif;
}

@media (min-width: 1400px) {
    #residence-button {
        font-size: 1.5rem;
        padding: 20px;
        border: none;
        border-radius: 50px;
        line-height: 0.8;
        background: #0e6ba8;
        transition: all 300ms;
    }
    #begin-button {
        font-size: 1.5rem;
        padding: 20px;
        border: none;
        border-radius: 50px;
        line-height: 0.8;
        background: #fff;
        color: #151141;
        border: 1px solid #151141;
        transition: all 300ms;
    }
    .wizzard-residencia {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 80px;
    }
    .si-no-container {
        display: flex;
        justify-content: center;
    }
}

#f-container {
    display: grid;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
}
#c-form {
    display: grid;
    justify-content: center;
    background: #151141;
    padding: 80px;
    border-radius: 25px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.check-logo {
    width: 30px;
}
.check-logo-white {
    width: 1.4em;
}
#c-form label {
    color: #fff;
}
#form-title {
    margin-top: 20px;
    font-size: 24px;
    color: #fff;
    margin-bottom: 12px;
    font-weight: bold;
    display: block;
    text-align: center;
}
#c-form form {
    display: flex;
    flex-direction: column;
}
#c-form label {
    margin-bottom: 10px;
}
#c-form input {
    padding: 10px;
    border: none;
    border-radius: 25px;
    margin-bottom: 20px;
}
#c-form input:focus {
    outline: none;
    box-shadow: 0px 4px 8px #3eb2ff;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
#c-form button[type="submit"] {
    background-color: #fff;
    color: #0e6ba8;
    font-size: 2em;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 50%;
    align-self: center;
}
#c-form button[type="submit"]:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    box-shadow: 0px 4px 8px #3eb2ff;
}
#list-container{
    display: grid;
    justify-content: center;
    margin-top: 70px;
    padding: 30px;
}
.start {
    font-size: 2em;
    font-weight: 600;
    line-height: 1;
    margin: 30px auto;
    text-align: center;
}
.list-item {
    list-style: none;
    margin: 10px 50px ;
}
.residencia-text {
    font-size: 2em;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    align-self: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 1em;
}
.wizzard-residencia {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 80px 0 80px 0;
}
.si-no-container {
    display: flex;
    justify-content: center;
}
#date-container {
    display: grid;
    justify-content: center;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    height: 20em;
}
.dec-date {
    color: #fff;
    font-size: 2em;
    font-weight: 600;
    margin: 20px 0 0 0;
    text-align: center;
    padding-top: 30px;
}
#residence-button {
    font-size: 2em;
    padding: 30px 30px;
    border: none;
    border-radius: 50px;
    width: 30em;
    line-height: 0.8;
    background: #0e6ba8;
    transition: all 300ms;
}
#residence-button:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background: #151141;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
#begin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    color: #fff;
    margin-top: 50px;
    padding: 5em;
}
.texto-content {
    font-size: 2em;
    margin: 20px auto;
    color: #fff;
}
.p-content {
    font-size: 1.5em;
    padding: 10px 50px;
    margin-bottom: 20px;
}
#begin-button {
    font-size: 2em;
    padding: 30px 30px;
    border: none;
    border-radius: 50px;
    width: 30em;
    line-height: 0.8;
    background: #fff;
    color: #151141;
    border: 1px solid #151141;
    transition: all 300ms;
}
#begin-button:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background: #151141;
    color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}


@media (max-width:768px) {
    #f-container {
        margin-top: 90px;
    }
    #c-form {
        max-width: 350px;
        padding: 0 20px;
        box-sizing: border-box;
        height: 22em;
    }
    #form-title {
        font-size: 1.2em;
    }
    .check-logo {
        width: 1.2em;
    }
    #list-container{
        margin-top: 90px;
        padding: 20px;
    }
    .check-logo-white {
        width: 1.2em;
    }
    .residencia-text {
        font-size: 1.5em;
        width: 15em;
    }
    .wizzard-residencia {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 40px 0 40px 0;
    }
    #btn-residencia:hover {
        background-color: #151141;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
    .si-no-container {
        display: flex;
        justify-content: center;
    }
    #date-container {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .start, 
    .dec-date {
        font-size: 1.5em;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .list {
        padding: 10px;
    }
    .list-item {
        font-size: 1em;
        margin: 10px 5px;
    }
    #residence-button {
        font-size: 1.2rem;
        width: 18em;
        border: none;
        border-radius: 50px;
        line-height: 0.8;
        background: #0e6ba8;
        transition: all 300ms;
    }
    #residence-button:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        background: #151141;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #begin-container {
        margin-top: 0;
        padding: 1em;
    }
    .texto-content {
        font-size: 1.5em;
        margin: 20px auto;
    }
    .p-content {
        font-size: 1.2em;
        line-height: 1em;
        padding: 10px 20px;
        margin-top: 20px;
        order: 2;
    }
    #begin-button {
        font-size: 1.2rem;
        width: 18em;
        border: none;
        border-radius: 50px;
        line-height: 0.8;
        background: #fff;
        color: #151141;
        border: 1px solid #151141;
        transition: all 300ms;
    }
    #begin-button:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        background: #151141;
        color: #fff;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
}
.pl,
.pl__worm {
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.pl {
    -webkit-animation-name: bump5;
            animation-name: bump5;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    width: 5em;
    height: 5em;
}

.pl__ring {
    stroke: hsla(var(--hue),10%,10%,0.1);
    transition: stroke 0.3s;
}

.pl__worm {
    -webkit-animation-name: worm5;
            animation-name: worm5;
    -webkit-animation-timing-function: cubic-bezier(0.42,0.17,0.75,0.83);
            animation-timing-function: cubic-bezier(0.42,0.17,0.75,0.83);
}

/* Animations */
@-webkit-keyframes bump5 {
    from,
    42%,
    46%,
    51%,
    55%,
    59%,
    63%,
    67%,
    71%,
    74%,
    78%,
    81%,
    85%,
    88%,
    92%,
    to {
    -webkit-transform: translate(0,0);
            transform: translate(0,0);
    }

    44% {
    -webkit-transform: translate(1.33%,6.75%);
            transform: translate(1.33%,6.75%);
    }

    53% {
    -webkit-transform: translate(-16.67%,-0.54%);
            transform: translate(-16.67%,-0.54%);
    }

    61% {
    -webkit-transform: translate(3.66%,-2.46%);
            transform: translate(3.66%,-2.46%);
    }

    69% {
    -webkit-transform: translate(-0.59%,15.27%);
            transform: translate(-0.59%,15.27%);
    }

    76% {
    -webkit-transform: translate(-1.92%,-4.68%);
            transform: translate(-1.92%,-4.68%);
    }

    83% {
    -webkit-transform: translate(9.38%,0.96%);
            transform: translate(9.38%,0.96%);
    }

    90% {
    -webkit-transform: translate(-4.55%,1.98%);
            transform: translate(-4.55%,1.98%);
    }
}
@keyframes bump5 {
    from,
    42%,
    46%,
    51%,
    55%,
    59%,
    63%,
    67%,
    71%,
    74%,
    78%,
    81%,
    85%,
    88%,
    92%,
    to {
    -webkit-transform: translate(0,0);
            transform: translate(0,0);
    }

    44% {
    -webkit-transform: translate(1.33%,6.75%);
            transform: translate(1.33%,6.75%);
    }

    53% {
    -webkit-transform: translate(-16.67%,-0.54%);
            transform: translate(-16.67%,-0.54%);
    }

    61% {
    -webkit-transform: translate(3.66%,-2.46%);
            transform: translate(3.66%,-2.46%);
    }

    69% {
    -webkit-transform: translate(-0.59%,15.27%);
            transform: translate(-0.59%,15.27%);
    }

    76% {
    -webkit-transform: translate(-1.92%,-4.68%);
            transform: translate(-1.92%,-4.68%);
    }

    83% {
    -webkit-transform: translate(9.38%,0.96%);
            transform: translate(9.38%,0.96%);
    }

    90% {
    -webkit-transform: translate(-4.55%,1.98%);
            transform: translate(-4.55%,1.98%);
    }
}

@-webkit-keyframes worm5 {
    from {
    stroke-dashoffset: 10;
    }

    25% {
    stroke-dashoffset: 295;
    }

    to {
    stroke-dashoffset: 1165;
    }
}

@keyframes worm5 {
    from {
    stroke-dashoffset: 10;
    }

    25% {
    stroke-dashoffset: 295;
    }

    to {
    stroke-dashoffset: 1165;
    }
}

#declaracion-container {
    margin-top: 90px;
    display: grid;
}
#welcome-container {
    width: 100%;
    background: #fff url(/static/media/list-man.8690e222.png) right center/contain no-repeat;
    margin-bottom: 20px;
}
#welcome-title {
    font-size: 2em;
    color: #151141;
    font-weight: 600;
}
#title-logo {
    width: 1em;
    margin-bottom: 10px;
}

#welcome-message {
    color: #151141;
    font-size: 1.2em;
    font-weight: 600;
    width: 60%;
    text-align: justify;
}
#welcome-message2 {
    color: #151141;
    font-size: 1em;
    font-weight: 600;
    width: 60%;
    text-align: justify;
}
#welcome-message3,
#welcome-message4 {
    color: #151141;
    font-size: 1em;
    font-weight: 600;
    text-align: justify;
}
.check-logo-white {
    width: 1.2em;
    margin-bottom: .5em;
}
.list-item-welcome {
    list-style: none;
    font-weight: 600;
}
#new-declaracion {
    width: 20%;
    font-size: 1.2em;
    font-weight: 600;
}

.ayuda-año-gravable {
    height: 17px !important;
}

@media (max-width: 768px) {
    #welcome-container {
        background: #fff;
        margin-bottom: 20px;
    }
    #welcome-title {
        font-size: 1.6em;
        color: #151141;
        font-weight: 600;
    }
    #title-logo {
        width: 1em;
        margin-bottom: 10px;
    }
    
    #welcome-message {
        color: #151141;
        font-size: 1.2em;
        font-weight: 600;
        width: 100%;
        text-align: justify;
    }
    #welcome-message2 {
        color: #151141;
        font-size: 1em;
        font-weight: 600;
        width: 100%;
        text-align: justify;
    }
    #welcome-message3,
    #welcome-message4 {
        color: #151141;
        font-size: 1em;
        font-weight: 600;
        text-align: justify;
    }
    .check-logo-white {
        width: 1.2em;
        margin-bottom: .5em;
    }
    .list-welcome {
        background: #fff url(/static/media/list-man.8690e222.png) right center/contain no-repeat;
        padding-left: 0;
    }
    .list-item-welcome {
        list-style: none;
        font-weight: 600;
    }
    #new-declaracion {
        width: 100%;
        font-size: 1.2em;
        font-weight: 600;
    }
}

@media (max-width: 680px) {
    #welcome-container {
        background: #fff;
        margin-bottom: 20px;
    }
    #welcome-title {
        font-size: 1.4em;
        color: #151141;
        font-weight: 600;
    }
    #title-logo {
        width: .8em;
        margin-bottom: 10px;
    }
    
    #welcome-message {
        color: #151141;
        font-size: 1.2em;
        font-weight: 600;
        width: 100%;
        text-align: justify;
    }
    #welcome-message2 {
        color: #151141;
        font-size: 1em;
        font-weight: 600;
        width: 100%;
        text-align: justify;
    }
    #welcome-message3,
    #welcome-message4 {
        color: #151141;
        font-size: 1em;
        font-weight: 600;
        text-align: justify;
    }
    .check-logo-white {
        width: 1.2em;
        margin-bottom: .5em;
    }
    .list-welcome {
        background: #fff url(/static/media/list-man.8690e222.png) right center/contain no-repeat;
        padding-left: 0;
    }
    .list-item-welcome {
        list-style: none;
        font-weight: 600;
        font-size: .9em;
    }
    #new-declaracion {
        width: 100%;
        font-size: 1.2em;
        font-weight: 600;
    }
}
#form-container {
    display: grid;
    justify-content: center;
    margin: auto;
}
img {
    width: 50%;
    align-self: center;
}

#contact-form {
    background: #fff;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}

#contact-form label {
    color: #0e6ba8;
}

#contact-form #heading {
    font-size: 24px;
    color: #0e6ba8;
    margin-bottom: 12px;
    font-weight: bold;
    display: block;
}

#contact-form form {
    display: flex;
    flex-direction: column;

}

#contact-form label {
    margin-bottom: 10px;
}

#heading {
    margin-top: 20px;
}

textarea {
    resize: none;
    height: 80px;

}

#contact-form input, #contact-form textarea {
    padding: 10px;
    border: none;
    border-radius: 25px;
    margin-bottom: 20px;
    background-color: rgb(240, 238, 238);
    color: #151141;
}

#contact-form input:focus, #contact-form textarea:focus {
    outline: none;
    box-shadow: 0px 4px 8px #3eb2ff;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

#contact-form button[type="submit"] {
    background-color: #0e6ba8;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 200px;
    align-self: center;
}

#contact-form button[type="submit"]:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #151141;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}

.forms-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 30px;
    min-height: 430px;
}

.ti-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
    margin: auto;
}
.title {
    margin: auto;
    width: 700px;
    font-size: 2em;
    text-align: center;
    line-height: 1;
}
.img-contact {
    width: 300px;
}

.mensaje-enviado {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .forms-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .ti-container {
        grid-gap: 30px;
        gap: 30px;
    }
    .title {
        margin-top: 60px;
        max-width: 450px;
        text-align: center;
        font-size: 1.5em;
    }
    .img-contact {
        width: 200px;
    }
}
#footer {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
    padding: 30px 20px 0;
}
#logo {
    width: 200px;
}
#p-footer {
    padding: 10px;
    margin: auto;
    font-size: 1rem;
}
* {
  font-family: 'Montserrat', sans-serif;
}

.firebaseui-idp-google > .firebaseui-idp-text::after{
  font-size: 20px;
  content: "Ingresar con Google";
}
.firebaseui-idp-google > .firebaseui-idp-text{
  font-size: 0;
}
.firebaseui-idp-google > .firebaseui-idp-text-short ::after{
  font-size: 14px;
  content: "Google";
}
.firebaseui-idp-google > .firebaseui-idp-text-short {
  font-size: 0;
}
.firebaseui-idp-password > .firebaseui-idp-text::after{
  font-size: 20px;
  content: "Ingresar con Correo";
}
.firebaseui-idp-password > .firebaseui-idp-text{
  font-size: 0;
}
.firebaseui-idp-password > .firebaseui-idp-text-short ::after{
  font-size: 20px;
  content: "Correo";
}
.firebaseui-idp-password > .firebaseui-idp-text-short {
  font-size: 0;
}
.principal-container {
  padding: 0;
}
.btn-outline-info {
  color: #fff;
  background-color: #151141;
  border: none;
}
.btn-outline-info:hover {
  background-color: #0e6ba8;
}
.jumbotron {
  background: #151141;
  border-radius: 25px;
}
.d-flex.justify-content-start {
  margin: 80px 0 0 30px;
}
.dropdown-menu {
  border-radius: 25px;
  background: #151141;
  border: #0e6ba8 1px solid; 
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.dropdown-header, 
.dropdown-item {
  color: #fff;
  border-radius: 25px;
}
.modal-content {
  border-radius: 25px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.form-label {
  color: black;
  font-size: 1.2rem;
}
.form-control {
  border-radius: 25px;
  border: #0e6ba8 1px solid;
  width: 95%;
  margin-right: 10px;
}
.my-1 {
  border-radius: 25px;
  border: #0e6ba8 1px solid; 
}
.btn-primary {
  background: #0e6ba8;
  border: none;
  border-radius: 25px;
}
.btn-secondary {
  border-radius: 25px;
}
.btn-primary:hover {
  background: #151141;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
#dropdown-basic {
  background-color: #151141;
  border: none;
}
.btn-outline-primary,
.btn-outline-secondary {
  border-radius: 25px;
}
.fa-question-circle {
  color: #151141;
}
.card {
  margin: 20px;
  padding: 10px;
  min-width: 40%;
  /* max-width: 300px; */
  min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}
.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}
.card-header {
  background: #0e6ba8;
  color: #fff;
  font-size: 1.5rem;
  position: absolute;
  width: 100%;
}

@media screen {
  
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50px;
}
.tooltip-inner {
  background-color: #151141;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}
.ml-2 {
  color: #ffffff;
  font-size: 2.5rem;
}
h1 {
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 2rem;
  color: #151141;
  font-weight: 600;
}
h3 {
  background-color: #151141;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 5px 20px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.col-sm-12 {
  padding: 0;
}
.card-deck {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
.col-md-3{
  padding-left: 50px;
}

@media (max-width: 768px) {
  .card-deck {
    flex-direction: column;
  }
  .col-md-3 {
    width: 350px;
  }
  .sidebar.is-open {
    height: 330px;
    margin-bottom: 20px;
  }
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  min-width: 0;
  max-width: 250px;
  background: #151141;
  color: #ffffff;
  transition: all 0.5s;
  width: 60px;
  border-radius: 25px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.sidenav-label{
  display:inline-block;
}
.sidebar .sidenav-label {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 1s;
}
.sidebar.is-open .sidenav-label {
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
  transition: all 1s;
}
.sidebar.is-open {
  min-width: 100%;
  margin-left: 0;
  transition: .5s;
}
.sidebar.is-open, a {
  display: block;
}
.sidebar-header {
  background: #151141;
  color: #adb5bd;
}
.sidebar-header h3{
  color: #fff;
  padding: 1em;
}
.sidebar ul p {
  color: #fff;
  padding: 10px;
}
.collapse.show, 
.collapsing {
  background: white;
}
.sidebar .nav-item:hover {
  background: #0e6ba8;
  border-radius: 15px;
  color: #fff;
  transition: .2s;
}
.nav-item .svg-inline--fa {
  color: inherit;
}
li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}
.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}
.carousel-item-content {
  min-width: 150px;
  min-height: 100px;
  max-width: 80%;
  padding: 20px;
  overflow: hidden;
  display: block;
  margin: auto;
}
.si-no-container > button{
  width: 60px;
  border-radius: 25px;
  border: #0e6ba8 1px solid;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 45px;
  width: 45px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}
.carousel-control-next-icon:after {
  content: '>';
  font-size: 25px;
  color: #1762A0;
}
.carousel-control-prev-icon:after {
  content: '<';
  font-size: 25px;
  color: #1762A0;
}

.carousel-indicators li {
  background-color: #1762A0;;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}
@media only screen and (max-width: 575.98px) {
  body {
    overflow:  scroll;
  }
  .content.is-open {
    display: none;
    transition: all 0.5s;
  }
  .sidebar.is-open {
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }
  .sidebar.is-open > .sidebar-header button {
    display: block;
  }
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
div.vertical {
  margin-left: -85px;
  margin-right: -85px;
  width: auto;
  height: auto;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg); /* IE 9 */
  white-space: nowrap;
  padding: 10px;
}
td.vertical {
  background-color: #fff;
  height: 150px;
  line-height: 14px;
  padding-bottom: 20px;
  text-align: left;
  border:2px solid black;
}
tr.section-top {
  border-top:2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid black;
}
tr.section-middle {
  border-right: 2px solid black;
}
tr.section-bottom {
  border-bottom:2px solid black;
  border-right: 2px solid black;
}
.opcion-multiple {
  min-width: 250px;
}
.preguntaEscondida {
  display: none;
}
.completado {
  color: #8ccbff;
}
.seleccion-multiple{
  margin-top:0px !important;
  border: #0e6ba8 1px solid;
  border-radius: 25px;
  width:100%;
  padding: 8px;
  font-size: 1rem;
}
.contenedor-opcion {
  margin-top: 15px;
  text-align: right;
}
.opcion-check{
  margin-left: 5px;
  margin-right:2px;
}
.opcion-help {
  padding-right:10px;
  color: #151141;
}


.marginAuto{
  margin: auto;
  margin-left: 5px;
}

.docsParagraph{
  color: #151141;
  font-size: 1em;
  font-weight: 600;
  text-align: justify;
}

.docsParagraphFinal{
  font-style:italic;
  text-align: center;
  color: #0e6ba8;
  font-size: 1em;
  font-weight: 600;
}

.link {
  display: inline;
  text-decoration: underline;
  color:#0e6ba8;
}



.guia {
  height: 90vh;
}

.guia-item {
  max-height: 85vh;
  object-fit: contain;
  width: 100%;
}

.instruction {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .guia {
    height: 60vh;
  }
  
  .guia-item {
    max-height: 55vh;
    object-fit: contain;
    width: 100%;
  }
  .instruction {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.guia-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 26px;
}

.btn-guia {
  padding:16px
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

