#form-container {
    display: grid;
    justify-content: center;
    margin: auto;
}
img {
    width: 50%;
    align-self: center;
}

#contact-form {
    background: #fff;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}

#contact-form label {
    color: #0e6ba8;
}

#contact-form #heading {
    font-size: 24px;
    color: #0e6ba8;
    margin-bottom: 12px;
    font-weight: bold;
    display: block;
}

#contact-form form {
    display: flex;
    flex-direction: column;

}

#contact-form label {
    margin-bottom: 10px;
}

#heading {
    margin-top: 20px;
}

textarea {
    resize: none;
    height: 80px;

}

#contact-form input, #contact-form textarea {
    padding: 10px;
    border: none;
    border-radius: 25px;
    margin-bottom: 20px;
    background-color: rgb(240, 238, 238);
    color: #151141;
}

#contact-form input:focus, #contact-form textarea:focus {
    outline: none;
    box-shadow: 0px 4px 8px #3eb2ff;
    transform: scale(1.02);
    transition: transform 0.3s ease-in-out;
}

#contact-form button[type="submit"] {
    background-color: #0e6ba8;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 200px;
    align-self: center;
}

#contact-form button[type="submit"]:hover {
    transform: scale(1.1);
    background-color: #151141;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
