@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap');


.justify-content-end {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    transition: all 300ms;
}
#navbar {
    font-family: 'Montserrat', sans-serif;
    margin-left: auto;
    gap: 10px;
}
.logo {
    width: 180px;
    margin-left: 50px;
}
.navbar-toggler.collapsed,
.navbar-toggler {
    border-radius: 3em;
    border: none;
}
#menu.nav-link {
    font-size: 1em;
    color: #151141;
    border-radius: 25px;
    transition: all 300ms;
    padding: 10px 20px;
}
#menu.nav-link:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgb(190, 190, 190);
    color: #fff;
    background-color: #0e6ba8;
}
#qsLoginBtn {
    font-size: 1em;
    margin-right: 100px;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background: #0e6ba8;
    transition: all 300ms;
}
#qsLoginBtn.btn-primary:hover {
    transform: scale(1.05);
    background: #151141;
    border: none;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.dropdown-toggle.btn.btn-secondary {
    margin-right: 150px;
    padding: 10px 20px;
    font-size: 1rem;
    background: #0e6ba8;
    border: none;
    border-radius: 25px;
}


@media (max-width: 768px) {
    .logo {
        margin: 0;
        width: 150px;
    }
    #qsLoginBtn {
        margin-right: 0px;
    }
    #menu.nav-link {
        width: 320px;
    }
}
