@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

@media (min-width: 1400px) {
    #contenedor {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 55em;
        margin-top: 80px;
        padding: 0 80px;
        background: radial-gradient(#0e6aa8b0, #0e6ba8);
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #c-div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    #c-h {
        color: #fff;
        font-size: 3rem;
        font-weight: 800;
        width: 600px;
        margin: 0 0 30px 0;
        line-height: 1;
    }
    #c-p {
        font-size: 2rem;
        width: 600px;
        padding: 0 20px 30px;
        color: #fff;
        line-height: 1;
    }
    #pc {
        width: 700px;
        padding: 20px;
    }
    #loginBtn {
        font-size: 2rem;
        font-weight: 600;
        padding: 20px;
        border: none;
        border-radius: 50px;
        width: fit-content;
        line-height: 0.8;
        background: #fff;
        color: #0e6ba8;
        border: 1px solid #0e6ba8;
        transition: all 300ms;
    }
    #loginBtn:hover {
        transform: scale(1.05);
        background: #fff;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        color: #151141;
        border: 2px solid #151141;
    }
    #section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40em;
        margin: 0;
        color: #151141;
        line-height: 1;
    }
    #section:hover {
        cursor: pointer;
    }
    #sino {
        width: 30em;
    }
    #p-sec,
    #p-sec2 {
        font-size: 2rem;
        font-weight: 600;
        margin: 0;
    }
    #section-3 {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        background: radial-gradient(#0e6aa8b0, #0e6ba8);
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #ventajas {
        padding: 0 50px;
        font-size: 2.5rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 0.5;
    }
    .logo-text {
        width: 250px;
    }
    .logo-text {
        cursor: pointer;
    }
    #boxes {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 80px;
        gap: 80px;
        padding: 1em;
    }
    #box {
        width: 300px;
        font-size: 1.5rem;
        border-radius: 25px;
        text-align: center;
        /* background: radial-gradient(#0e6aa8b0, #0e6ba8); */
        color: white;
        transition: all 300ms;
    }
    #box:hover {
        transform: scale(1.05);
        cursor: pointer;
        /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25); */
    }
    #cerdito,
    #reloj,
    #candado {
        width: 200px;
    }
    .box-text {
        color: #fff;
        border-radius: 25px;
        font-weight: 800;
    }
    .p-box {
        color: #fff;
    
    }
    
    #section-5 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
    }
    #texto-content {
        width: 20em;
        font-size: 3rem;
        font-weight: 600;
        color: #151141;
        line-height: 1;
    }
    #begin-btn {
        font-size: 2rem;
        padding: 30px;
        border: none;
        border-radius: 50px;
        width: 100%;
        line-height: 0.8;
        background: #0e6ba8;
        transition: all 300ms;
        margin-bottom: 30px;
    }
    #begin-btn:hover {
        transform: scale(1.05);
        background: #151141;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #parr-content {
        font-size: 1.5rem;
        line-height: 1;
        width: 40em;
        margin: 0 0 80px;
    }
}
#contenedor {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
    padding: 0 80px;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    gap: 5em;
}
#c-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
#c-h {
    color: #fff;
    font-size: 3rem;
    font-weight: 800;
    width: 600px;
    margin: 0 0 30px 0;
    line-height: 1;
}
#c-p {
    font-size: 2rem;
    width: 600px;
    padding: 0 20px 30px;
    color: #fff;
    line-height: 1;
}
#pc {
    width: 700px;
    padding: 20px;
}
#loginBtn {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 20px;
    border: none;
    border-radius: 50px;
    width: fit-content;
    line-height: 0.8;
    background: #fff;
    color: #0e6ba8;
    border: 1px solid #0e6ba8;
    transition: all 300ms;
}
#loginBtn:hover {
    transform: scale(1.05);
    background: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    color: #151141;
    border: 2px solid #151141;
}
#section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 0;
    width: 100%;
    margin: 0;
    padding: 5em;
    text-align: center;
    color: #151141;
    line-height: 1;
}
#sino {
    width: 20em;
}
#section:hover {
    cursor: pointer;
}
#p-sec2 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    width: 50%;
}
#section-3 {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
#ventajas {
    padding: 0 50px;
    font-size: 2.5rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 0.5;
}
.logo-text {
    width: 250px;
}
.logo-text {
    cursor: pointer;
}
#boxes {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 80px;
    gap: 80px;
    padding: 1em;
}
#box {
    width: 300px;
    font-size: 1.5rem;
    border-radius: 25px;
    text-align: center;
    /* background: radial-gradient(#0e6aa8b0, #0e6ba8); */
    color: white;
    transition: all 300ms;
}
#box:hover {
    transform: scale(1.05);
    cursor: pointer;
    /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25); */
}
#cerdito,
#reloj,
#candado {
    width: 200px;
}
.box-text {
    color: #fff;
    border-radius: 25px;
    font-weight: 800;
}
.p-box {
    color: #fff;

}
#section-5 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
}
#texto-content {
    width: 20em;
    font-size: 3rem;
    font-weight: 600;
    color: #151141;
    line-height: 1;
}
#begin-btn {
    font-size: 2rem;
    padding: 30px;
    border: none;
    border-radius: 50px;
    width: 100%;
    line-height: 0.8;
    background: #0e6ba8;
    transition: all 300ms;
    margin-bottom: 30px;
}
#begin-btn:hover {
    transform: scale(1.05);
    background: #151141;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
#parr-content {
    font-size: 1.5rem;
    line-height: 1;
    width: 40em;
    margin: 0 0 80px;
}


@media (max-width: 768px) {
    #contenedor {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        gap: 0;
        height: auto;
        /* background: linear-gradient(-160deg, #0e6ba8 55%, #151141 40%); */
        background: radial-gradient(#0e6aa8b0, #0e6ba8);
    }
    #c-h {
        font-size: 2rem;
        font-weight: 800;
        width: 100%;
        margin: 100px 0 10px 0;
        text-align: center;
        color: #fff;
    }
    #c-p {
        font-size: 1.5rem;
        width: 100%;
        padding: 0 10px;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
    }
    #loginBtn,
    #begin-btn {
        font-size: 1.5rem;
        padding: 15px;
        border: 1px solid #0e6ba8;
        border-radius: 50px;
        width: 100%;
        line-height: 0.8;
        background: #fff;
        transition: all 300ms;
    }
    #loginBtn:hover,
    #begin-btn:hover {
        transform: scale(1.05);
        background: #fff;
        color: #151141;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        border: 2px solid #151141;
    }
    #pc {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
    #section {
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 10px;
        margin: 20px 0 20px 0;
        color: #151141;
        line-height: 1.2;
        transition: all 300ms;
    }
    #section:hover {
        transform: scale(1.05);
    }
    #sino {
        width: 15em;
        order: 1;
    }
    #p-sec2 {
        font-size: 1.5rem;
        width: 100%;
        order: 0;
    }
    #section-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    }
    #ventajas {
        margin: 30px 0 0 0;
        padding: 10px;
        font-size: 2rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 1;
    }
    .logo-text {
        width: 200px;
    }
    #boxes {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    #box {
        width: 100%;
        font-size: 1.5rem;
        border-radius: 25px;
        text-align: center;
        color: white;
        transition: all 300ms;
    }
    #cerdito,
    #reloj,
    #candado {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }

    .box-text {
        color: #fff;
        border-radius: 20px;
        font-size: 2rem;
    }
    .p-box {
        font-size: 1.5rem;
        line-height: 1em;
    }

    #section-5 {
        height: auto;
        text-align: center;
        display: block;
    }

    #texto-content {
        width: 15.5em;
        padding: 1em;
        margin: 19.2px 0 0 0;
        font-size: 1.5rem;
        font-weight: 700;
        color: #151141;
        line-height: 1;
    }

    #parr-content {
        font-size: 1.2rem;
        line-height: 1.2;
        width: fit-content;
        padding: 1em;
        max-width: 600px;
        margin-bottom: 1em;
    }

    #begin-btn {
        font-size: 1.2rem;
        margin: 0;
        padding: 15px;
        border: none;
        border-radius: 50px;
        width: 18em;
        max-width: 400px;
        line-height: 1.2;
        background: #0e6ba8;
        transition: all 300ms;
    }
    #begin-btn:hover {
        background-color: #151141;
        color: #fff;
    }
}
