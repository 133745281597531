.cal-btn {
    border-radius: 2em;
    width: 75%;
    padding: 30px;
    font-size: 2em;
    line-height: 0.8;
    background: white;
    color: #0e6ba8;
    border: 1px solid #0e6ba8;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 300ms;
}
.cal-btn:hover {
    transform: scale(1.05);
    background-color: #151141;
    color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    border: none;
}

@media (max-width: 768px) {
    .cal-btn {
        width: 18em;
    }
}

@media (min-width: 1400px) {
    .cal-btn {
        width: 30em;
        font-size: 2rem;
        padding: 30px;
    }
}
