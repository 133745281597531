.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
}
.pl,
.pl__worm {
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

.pl {
    animation-name: bump5;
    animation-timing-function: linear;
    width: 5em;
    height: 5em;
}

.pl__ring {
    stroke: hsla(var(--hue),10%,10%,0.1);
    transition: stroke 0.3s;
}

.pl__worm {
    animation-name: worm5;
    animation-timing-function: cubic-bezier(0.42,0.17,0.75,0.83);
}

/* Animations */
@keyframes bump5 {
    from,
    42%,
    46%,
    51%,
    55%,
    59%,
    63%,
    67%,
    71%,
    74%,
    78%,
    81%,
    85%,
    88%,
    92%,
    to {
    transform: translate(0,0);
    }

    44% {
    transform: translate(1.33%,6.75%);
    }

    53% {
    transform: translate(-16.67%,-0.54%);
    }

    61% {
    transform: translate(3.66%,-2.46%);
    }

    69% {
    transform: translate(-0.59%,15.27%);
    }

    76% {
    transform: translate(-1.92%,-4.68%);
    }

    83% {
    transform: translate(9.38%,0.96%);
    }

    90% {
    transform: translate(-4.55%,1.98%);
    }
}

@keyframes worm5 {
    from {
    stroke-dashoffset: 10;
    }

    25% {
    stroke-dashoffset: 295;
    }

    to {
    stroke-dashoffset: 1165;
    }
}
