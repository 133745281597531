* {
  font-family: 'Montserrat', sans-serif;
}

.firebaseui-idp-google > .firebaseui-idp-text::after{
  font-size: 20px;
  content: "Ingresar con Google";
}
.firebaseui-idp-google > .firebaseui-idp-text{
  font-size: 0;
}
.firebaseui-idp-google > .firebaseui-idp-text-short ::after{
  font-size: 14px;
  content: "Google";
}
.firebaseui-idp-google > .firebaseui-idp-text-short {
  font-size: 0;
}
.firebaseui-idp-password > .firebaseui-idp-text::after{
  font-size: 20px;
  content: "Ingresar con Correo";
}
.firebaseui-idp-password > .firebaseui-idp-text{
  font-size: 0;
}
.firebaseui-idp-password > .firebaseui-idp-text-short ::after{
  font-size: 20px;
  content: "Correo";
}
.firebaseui-idp-password > .firebaseui-idp-text-short {
  font-size: 0;
}
.principal-container {
  padding: 0;
}
.btn-outline-info {
  color: #fff;
  background-color: #151141;
  border: none;
}
.btn-outline-info:hover {
  background-color: #0e6ba8;
}
.jumbotron {
  background: #151141;
  border-radius: 25px;
}
.d-flex.justify-content-start {
  margin: 80px 0 0 30px;
}
.dropdown-menu {
  border-radius: 25px;
  background: #151141;
  border: #0e6ba8 1px solid; 
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.dropdown-header, 
.dropdown-item {
  color: #fff;
  border-radius: 25px;
}
.modal-content {
  border-radius: 25px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.form-label {
  color: black;
  font-size: 1.2rem;
}
.form-control {
  border-radius: 25px;
  border: #0e6ba8 1px solid;
  width: 95%;
  margin-right: 10px;
}
.my-1 {
  border-radius: 25px;
  border: #0e6ba8 1px solid; 
}
.btn-primary {
  background: #0e6ba8;
  border: none;
  border-radius: 25px;
}
.btn-secondary {
  border-radius: 25px;
}
.btn-primary:hover {
  background: #151141;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
#dropdown-basic {
  background-color: #151141;
  border: none;
}
.btn-outline-primary,
.btn-outline-secondary {
  border-radius: 25px;
}
.fa-question-circle {
  color: #151141;
}
.card {
  margin: 20px;
  padding: 10px;
  min-width: 40%;
  /* max-width: 300px; */
  min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}
.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}
.card-header {
  background: #0e6ba8;
  color: #fff;
  font-size: 1.5rem;
  position: absolute;
  width: 100%;
}

@media screen {
  
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50px;
}
.tooltip-inner {
  background-color: #151141;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}
.ml-2 {
  color: #ffffff;
  font-size: 2.5rem;
}
h1 {
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 2rem;
  color: #151141;
  font-weight: 600;
}
h3 {
  background-color: #151141;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 5px 20px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.col-sm-12 {
  padding: 0;
}
.card-deck {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
.col-md-3{
  padding-left: 50px;
}

@media (max-width: 768px) {
  .card-deck {
    flex-direction: column;
  }
  .col-md-3 {
    width: 350px;
  }
  .sidebar.is-open {
    height: 330px;
    margin-bottom: 20px;
  }
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  position: sticky;
  top: 100px;
  min-width: 0;
  max-width: 250px;
  background: #151141;
  color: #ffffff;
  transition: all 0.5s;
  width: 60px;
  border-radius: 25px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.sidenav-label{
  display:inline-block;
}
.sidebar .sidenav-label {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 1s;
}
.sidebar.is-open .sidenav-label {
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
  transition: all 1s;
}
.sidebar.is-open {
  min-width: 100%;
  margin-left: 0;
  transition: .5s;
}
.sidebar.is-open, a {
  display: block;
}
.sidebar-header {
  background: #151141;
  color: #adb5bd;
}
.sidebar-header h3{
  color: #fff;
  padding: 1em;
}
.sidebar ul p {
  color: #fff;
  padding: 10px;
}
.collapse.show, 
.collapsing {
  background: white;
}
.sidebar .nav-item:hover {
  background: #0e6ba8;
  border-radius: 15px;
  color: #fff;
  transition: .2s;
}
.nav-item .svg-inline--fa {
  color: inherit;
}
li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}
.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}
.carousel-item-content {
  min-width: 150px;
  min-height: 100px;
  max-width: 80%;
  padding: 20px;
  overflow: hidden;
  display: block;
  margin: auto;
}
.si-no-container > button{
  width: 60px;
  border-radius: 25px;
  border: #0e6ba8 1px solid;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 45px;
  width: 45px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}
.carousel-control-next-icon:after {
  content: '>';
  font-size: 25px;
  color: #1762A0;
}
.carousel-control-prev-icon:after {
  content: '<';
  font-size: 25px;
  color: #1762A0;
}

.carousel-indicators li {
  background-color: #1762A0;;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}
@media only screen and (max-width: 575.98px) {
  body {
    overflow:  scroll;
  }
  .content.is-open {
    display: none;
    transition: all 0.5s;
  }
  .sidebar.is-open {
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }
  .sidebar.is-open > .sidebar-header button {
    display: block;
  }
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
div.vertical {
  margin-left: -85px;
  margin-right: -85px;
  width: auto;
  height: auto;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg); /* IE 9 */
  white-space: nowrap;
  padding: 10px;
}
td.vertical {
  background-color: #fff;
  height: 150px;
  line-height: 14px;
  padding-bottom: 20px;
  text-align: left;
  border:2px solid black;
}
tr.section-top {
  border-top:2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid black;
}
tr.section-middle {
  border-right: 2px solid black;
}
tr.section-bottom {
  border-bottom:2px solid black;
  border-right: 2px solid black;
}
.opcion-multiple {
  min-width: 250px;
}
.preguntaEscondida {
  display: none;
}
.completado {
  color: #8ccbff;
}
.seleccion-multiple{
  margin-top:0px !important;
  border: #0e6ba8 1px solid;
  border-radius: 25px;
  width:100%;
  padding: 8px;
  font-size: 1rem;
}
.contenedor-opcion {
  margin-top: 15px;
  text-align: right;
}
.opcion-check{
  margin-left: 5px;
  margin-right:2px;
}
.opcion-help {
  padding-right:10px;
  color: #151141;
}


.marginAuto{
  margin: auto;
  margin-left: 5px;
}

.docsParagraph{
  color: #151141;
  font-size: 1em;
  font-weight: 600;
  text-align: justify;
}

.docsParagraphFinal{
  font-style:italic;
  text-align: center;
  color: #0e6ba8;
  font-size: 1em;
  font-weight: 600;
}

.link {
  display: inline;
  text-decoration: underline;
  color:#0e6ba8;
}



.guia {
  height: 90vh;
}

.guia-item {
  max-height: 85vh;
  object-fit: contain;
  width: 100%;
}

.instruction {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .guia {
    height: 60vh;
  }
  
  .guia-item {
    max-height: 55vh;
    object-fit: contain;
    width: 100%;
  }
  .instruction {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.guia-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 26px;
}

.btn-guia {
  padding:16px
}