#footer {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
    padding: 30px 20px 0;
}
#logo {
    width: 200px;
}
#p-footer {
    padding: 10px;
    margin: auto;
    font-size: 1rem;
}