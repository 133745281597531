.jumbo-container {
    display: grid;
    justify-content: center;
    height: 1vh;
}
.jumbotron {
    margin: 100px 300px;
    color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    width: fit-content;
}
.text-center {
    box-shadow: none;
}
.firebaseui-idp-button.mdl-button.mdl-js-button {
    border-radius: 25px;
    padding: 0 20px;
    font-size: 3rem;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in,
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in, 
.mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up {
    border-radius: 25px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.firebaseui-id-submit.mdl-button--colored {
    border-radius: 25px;
    background: #151141;
}
.firebaseui-id-secondary-link {
    color: #151141;
    border-radius: 25px;
}

@media (max-width: 768px) {
    .jumbotron {
        margin: 100px 0;
    }
}