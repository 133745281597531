.forms-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 30px;
    min-height: 430px;
}

.ti-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: auto;
}
.title {
    margin: auto;
    width: 700px;
    font-size: 2em;
    text-align: center;
    line-height: 1;
}
.img-contact {
    width: 300px;
}

.mensaje-enviado {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .forms-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .ti-container {
        gap: 30px;
    }
    .title {
        margin-top: 60px;
        max-width: 450px;
        text-align: center;
        font-size: 1.5em;
    }
    .img-contact {
        width: 200px;
    }
}