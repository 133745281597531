.section-6 {
    height: 25em;
    display: grid;
    align-items: center;
    justify-content: center;
    background: radial-gradient(#0e6aa8b0, #0e6ba8);
    color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    padding: 1em;
    text-align: center;
    padding: 50px;
}
.h-section {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #fff;
}
.p-section {
    font-size: 1.8em;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .section-6 {
        height: 25em;
        display: grid;
        align-items: center;
        justify-content: center;
    }
    .h-section {
        font-size: 1.5em;
        margin-top: 0;
        margin-bottom: 0.5em;
        color: #fff;
    }
    .p-section {
        font-size: 1.2em;
        line-height: 1.5;
    }
}